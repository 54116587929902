import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LaborOrderJob } from "src/app/shared/interfaces/labor-order-job.interface";
import { OrderSetBack } from "src/app/shared/interfaces/setback.interface";
import { API_URL } from "../../../../environments/settings";
import { LaborOrder, LaborOrderTemplate } from "../../../core/interfaces/labor-order.interface";
import { EnvironmentService } from "../../../core/services/environment.service";
import { LaborOrderState } from "../../../shared/enums/labor-order-state.enum";
import { LaborOrdersFilters } from "../interfaces/labor-orders-filters.interface";

@Injectable({ providedIn: "root" })
export class OrdersService {
  
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getOrders(skipCount, maxResult, filters?: LaborOrdersFilters) {
    if (this.env.isBrowser) {
      let filterParams: string = '';
			if (filters) {
				filterParams += filters.searchQ ? `&searchQ=${filters.searchQ}` : '';
				filterParams += filters.TerminalId ? `&TerminalId=${filters.TerminalId}` : '';
				filterParams += Number.isInteger(filters.LaborOrderState) &&  filters.LaborOrderState >= 0 ? `&LaborOrderState=${filters.LaborOrderState}` : '';
				filterParams += filters.StartDate ? `&StartDate=${filters.StartDate}` : '';
				filterParams += filters.StartTime ? `&StartTime=${filters.StartTime}` : '';
				filterParams += filters.EmployerId ? `&EmployerId=${filters.EmployerId}` : '';
			}
      return this.http.get<any>(`${this.apiUrl}/app/laborOrder/withFilters?MaxResultCount=${maxResult}&SkipCount=${skipCount}${filterParams}`);
    }
  }

  create(laborOrder: LaborOrder) {
    return this.http.post<any>(`${this.apiUrl}/app/laborOrder`, laborOrder);
  }

  update(laborOrder: LaborOrder) {
    return this.http.put<any>(`${this.apiUrl}/app/laborOrder/${laborOrder.id}`, laborOrder);
  }

  changeStatus(orderId: string, orderState: LaborOrderState) {
    return this.http.put<any>(`${this.apiUrl}/app/laborOrder/${orderId}/state`, { orderState });
  }

  getOrderById(orderId: string) {
    if (this.env.isBrowser) return this.http.get<any>(`${this.apiUrl}/app/laborOrder/${orderId}`);
  }

  getOrderByIdNoJobs(orderId: string) {
    if (this.env.isBrowser)
      return this.http.get<any>(`${this.apiUrl}/app/laborOrder/${orderId}/laborOrderNoJobs`);
  }

  getOrderJobById(detailId: string) {
    if (this.env.isBrowser)
      return this.http.get<any>(`${this.apiUrl}/app/laborOrder/laborOrderJobDetails/${detailId}`);
  }

  createDispatchOrder(orderId: string, jobInformationId: string) {
    return this.http.post<any>(`${this.apiUrl}/app/dispatchOrder/fromLaborJobDetail?laborOrderId=${orderId}&jobDetailId=${jobInformationId}`, null);
  }

  createJobTemplate(template) {
    return this.http.post<any>(`${this.apiUrl}/app/laborOrder/laborOrderJobTemplate`, template);
  }

  updateJobTemplate(id, template) {
    return this.http.put<any>(
      `${this.apiUrl}/app/laborOrder/${id}/laborOrderJobTemplate`,
      template
    );
  }

  getOrderTemplates() {
    return this.http.get<any>(`${this.apiUrl}/app/laborOrder/laborOrderTemplatesList`).toPromise();
  }

  getOrderTemplateDetails(id) {
    return this.http.get<any>(`${this.apiUrl}/app/laborOrder/${id}/laborOrderTemplate`);
  }

  deleteOrderTemplate(templateId: string) {
    return this.http.delete<any>(`${this.apiUrl}/app/laborOrder/${templateId}/laborOrderTemplate`).toPromise();
  }

  createOrderTemplate(template) {
    return this.http.post<any>(`${this.apiUrl}/app/laborOrder/laborOrderTemplate`, template);
  }

  updateOrderTemplate(id, template: LaborOrderTemplate) {
    return this.http.put<any>(`${this.apiUrl}/app/laborOrder/${id}/laborOrderTemplate`, template);
  }

  remove(orderId: string) {
    return this.http.delete(`${this.apiUrl}/app/laborOrder/${orderId}`);
  }

  saveDispatchNote(id: string, note: string) {
    return this.http.post<any>(`${this.apiUrl}/app/laborOrder/${id}/notes`, { note: note });
  }

  saveHead(laborOrderId: string, jobDetailId: string, head: string) {
    const queryParams: string = `head=${head}&laborOrderId=${laborOrderId}&jobDetailId=${jobDetailId}`;
    return this.http.post<any>(`${this.apiUrl}/app/laborOrder/head?${queryParams}`, {});
  }

  getSumarizeListLightInfo(date: string, unionId: number) {
    const params: HttpParams = new HttpParams({
      fromObject: {
        date,
        sortDescending: true,
        unionId
      }
    });
    return this.http.get<any>(`${this.apiUrl}/app/laborOrder/sumarizeList`, { params });
  }

  getSumarizeJobDetailList(laborOrderId: string, unionId: number, date: string) {
    return this.http.get<any>(
      `${this.apiUrl}/app/laborOrder/sumarizeJobDetailList?laborOrderId=${laborOrderId}&unionId=${unionId}&date=${date}`
    );
  }

  setBack(orderId: string, payload: OrderSetBack) {
    return this.http.put<any>(`${this.apiUrl}/app/laborOrder/${orderId}/setBack`, payload);
  }

  recallOrder(orderId: string, unionId: number, comment: string) {
    return this.http.put<any>(`${this.apiUrl}/app/laborOrder/${orderId}/recall/${unionId}`, { comment });
  }

  duplicateOrder(orderId: string, payload: any) {
    return this.http.post(`${this.apiUrl}/app/laborOrder/${orderId}/duplicate`, payload);
  }

  getLaborOrderJobDetails(orderId: string): Observable<LaborOrderJob[]> {
    return this.http.get<LaborOrderJob[]>(`${this.apiUrl}/app/laborOrder/duplicateLaborOrderJobDetails/${orderId}`);
  }
}
